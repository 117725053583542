import { accumulateGroupedKpi } from './reportUtils';

const groupReportDataByProductAd = (reportsContent, products) => {
  const groupedKeywords = reportsContent.reduce((acc, obj) => {
    const product = products.find((p) => p.adId === obj.adId);
    const key = obj.adId;
    return accumulateGroupedKpi(acc, obj, key, product);
  }, {});
  return groupedKeywords;
};

export default groupReportDataByProductAd;
