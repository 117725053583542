<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <Card>
      <template #title>
        <h2>{{ adGroupName }}</h2>
      </template>
      <template #content>
        <div class="text-right mb-3">
          <ReportsCalendar
            v-model="dateRange"
            report-type="CAMPAIGNS_AND_ADGROUPS"
          />
        </div>

        <ProductsTable
          :products="reports"
          :selected-profile="selectedProfile"
          :date-range="dateRange"
          :ad-group-id="adGroupId.toString()"
          :sums="reportsAggregation"
        />
      </template>
    </Card>
  </div>
  <Toast />
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import A2CAClient from '../../../../api/a2caAuthorizedClient';
import { ReportType, REPORT_DATE_FORMAT } from '../../../../constants/reportConstants';
import groupReportDataByProductAd from '../../../../utils/reports/productAdsReportUtils';
import ProductsTable from './products/ProductsTable.vue';
import { getDateRange } from '../../../../utils/dateUtils';
import ReportsCalendar from '../../../ReportsCalendar.vue';

export default {
  components: { ProductsTable, ReportsCalendar },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
    adGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      reports: null,
      reportsAggregation: null,
      dateRange: getDateRange(!!this.query?.mockAccountId),
    };
  },
  computed: {
    campaignId() {
      return this.campaign.campaignId;
    },
    adGroupId() {
      return this.adGroup.adGroupId;
    },
    adGroupName() {
      return this.adGroup.name;
    },
    ...mapGetters(['royalties']),

  },
  watch: {
    dateRange(range) {
      if (range[1] !== null) {
        this.retrieveReports();
      }
    },
    selectedProfile: {
      handler() {
        this.retrieveReports();
      },
      immediate: true,
    },
  },
  methods: {
    retrieveReports() {
      this.loading = true;
      this.reports = null;
      this.fetchReports();
    },
    async fetchReports() {
      const client = new A2CAClient(this.query);
      const reportsRequest = client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.PRODUCT_ADS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.adGroup.adGroupId,
        ReportType.AD_GROUPS,
        this.campaignId,
      );

      const productsRequest = client.getProductAds(this.selectedProfile.profileId,
        this.selectedProfile.region, this.adGroupId);

      const [reportsResponse, products] = await Promise.all([reportsRequest, productsRequest]);

      const { reportData } = reportsResponse.parsedReport;
      this.reportsAggregation = reportsResponse.aggregation;

      const groupedReports = groupReportDataByProductAd(reportData, products);

      this.reports = Object.values(groupedReports);
      this.loading = false;
    },
  },

};
</script>
